import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    loadProgression: 0,
    error: null,
  })),
  on(fromActions.partialLoadSuccess, (state, { items, loadProgression }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      loadProgression,
      error: null,
    }),
  ),
  on(fromActions.loadSuccess, (state) => ({
    ...state,
    loadProgression: null,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    loadProgression: null,
    isLoading: false,
    error,
  })),

  on(fromActions.loadLastPropertyIdRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.loadLastPropertyIdSuccess, (state, { id }) => ({
    ...state,
    lastPropertyId: id,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadLastPropertyIdFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function checkObReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
