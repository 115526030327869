import { createAction, props } from '@ngrx/store';

import { CheckOb, CheckObRequest } from '../../models';

export const loadRequest = createAction(
  '[Check Ob] Load Request',
  props<{ request: CheckObRequest }>(),
);
export const partialLoadSuccess = createAction(
  '[Check Ob] Partial Load Success',
  props<{
    items: CheckOb[];
    loadProgression: number;
  }>(),
);
export const loadSuccess = createAction('[Check Ob] Load Success');
export const loadFailure = createAction(
  '[Check Ob] Load Failure',
  props<{ error: any }>(),
);
export const loadLastPropertyIdRequest = createAction(
  '[Check Ob] Load Last Property Id Request',
);
export const loadLastPropertyIdSuccess = createAction(
  '[Check Ob] Load Last Property Id Success',
  props<{ id: number }>(),
);
export const loadLastPropertyIdFailure = createAction(
  '[Check Ob] Load Last Property Id Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Check Ob] Reset State');
