import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { CheckOb } from '../../models';

export const featureAdapter: EntityAdapter<CheckOb> =
  createEntityAdapter<CheckOb>({
    selectId: (model) => model.property_id,
  });

export interface State extends EntityState<CheckOb> {
  lastPropertyId: number;
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  loadProgression: number;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  lastPropertyId: null,
  isLoading: false,
  isSubmitLoading: false,
  loadProgression: null,
  error: null,
});
