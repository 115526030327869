import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { chunk, first, last, range } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { IResponseSuccess } from '../core/models/response-sucess.model';
import { observableSequence } from '../helpers';
import { CheckObRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CheckObService {
  constructor(private http: HttpClient) {}

  load(
    request: CheckObRequest,
    callBack?: (
      response: IResponseSuccess[],
      sources: Array<Observable<any>>,
    ) => void,
  ) {
    const chunkedProperties = chunk(
      range(+request.property_id_from, +request.property_id_to + 1),
      100,
    );

    return observableSequence(
      chunkedProperties.map((propertiesIds) => {
        const property_id_from = first(propertiesIds);
        const property_id_to = last(propertiesIds);

        return this.http.get<IResponseSuccess>(
          `general/tools/check_overbooking_risk?${generateSearchQuery({
            ...request,
            property_id_from,
            property_id_to,
          })}`,
        );
      }),
      callBack,
    );
  }

  loadLastPropertyId() {
    return this.http
      .get<IResponseSuccess>(
        `properties?page=1&active=1&order=created_at:DESC&lang=it`,
      )
      .pipe(
        map((response) => {
          return { data: { last_property_id: response.data[0].id } };
        }),
      );
  }
}
