import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { da } from 'date-fns/locale';
import { flatten } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CheckObService } from '../../services/check-ob.service';

import * as fromActions from './actions';
import * as fromState from './state';

@Injectable()
export class CheckObStoreEffects {
  constructor(
    private store: Store<fromState.State>,
    private dataService: CheckObService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService
          .load(request, (responses, sources) => {
            this.store.dispatch(
              fromActions.partialLoadSuccess({
                items: flatten(responses.map(({ data }) => data)),
                loadProgression: Math.ceil(
                  (100 * responses.length) / sources.length,
                ),
              }),
            );
          })
          .pipe(
            map(() => fromActions.loadSuccess()),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );

  loadLastPropertyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLastPropertyIdRequest),
      switchMap(() =>
        this.dataService.loadLastPropertyId().pipe(
          map(({ data }) =>
            fromActions.loadLastPropertyIdSuccess({
              id: data.last_property_id,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadLastPropertyIdFailure(error));
          }),
        ),
      ),
    ),
  );
}
